:root { 
--green_300:#78e378; 
--gray_50_01:#f8f8f8; 
--blue_gray_100_63:#cfdcee63; 
--gray_50_02:#fcf7f7; 
--light_green_A700:#2dde00; 
--blue_A200:#428eff; 
--white_A700_59:#ffffff59; 
--blue_gray_900_a5:#333333a5; 
--blue_gray_900:#333333; 
--red_A700:#f60000; 
--gray_600:#6f6f6f; 
--gray_400:#c7c2c1; 
--blue_gray_100:#d9d9d9; 
--gray_800:#444444; 
--blue_gray_500:#707991; 
--gray_200:#eaeaea; 
--blue_50:#eaf1ff; 
--light_green_A700_01:#4ec126; 
--indigo_400:#5f82b7; 
--indigo_200:#8babd8; 
--gray_100_01:#f5f5f5; 
--blue_300:#6da8ff; 
--blue_100:#bad1f3; 
--black_900_19:#00000019; 
--red_A700_01:#ff0000; 
--white_A700:#ffffff; 
--gray_900_ce:#1a1a1ace; 
--blue_A100:#74abff; 
--gray_50:#fafafc; 
--black_900:#000000; 
--blue_A200_02:#438eff; 
--blue_A200_01:#4f80e1; 
--gray_700:#666666; 
--gray_600_02:#787878; 
--gray_600_01:#808080; 
--gray_500:#949494; 
--gray_900:#1a1a1a; 
--gray_900_01:#011627; 
--blue_gray_900_7f:#3333337f; 
--gray_600_03:#818181; 
--gray_300:#dbdbdb; 
--gray_300_01:#d9dce0; 
--gray_100:#f3f3f3; 
--black_900_33:#00000033; 
--indigo_500_99:#3d70b899; 
--blue_400:#5a9cff; 
--blue_100_01:#bbd1f3; 
--blue_200:#95bfff; 
--indigo_500:#3a6bb5; 
}